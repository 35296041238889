import React from 'react'

import logoBg from '../images/logo.png'

const Header = () => { 
    return (
        <div>
            <div className="container mx-auto bg-white my-4">
                <div className="flex flex-row">
                    <div className="grow">
                        <div className='grid justify-items-center'>
                            <img src={logoBg} alt="logo brocoli" className='object-fill object-center'/>
                        </div>                    
                    </div>
                </div>
            </div>
        </div>
    )
 }

export default Header