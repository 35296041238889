import React from 'react'
import { ExclamationIcon } from '@heroicons/react/solid'

const Menu = (props) => {   

  const formules = props.formule
  const prix = props.prix
  const menuSoir = props.menuSoir 

  const inspiration = () => {
    return menuSoir.inspiration.map( (insp, i, arr) =>
      arr.length -1 === i ? <p key={i}>{insp}<br /></p> : <p key={i}>{insp}<br />&mdash;</p>
    )
  }

  const listPrix = prix.map( price => 
    <p key={price._id}>{price.nom} <span className='font-bold'>{price.prix.toFixed(2)} €</span></p>
  )  

  const listFormule = (type) => {
    return formules.filter( plat => plat.type === type)
                    .map( formule =>
                      <li key={formule._id} className='p-2 text-base text-gray-700'>{formule.nom} { formule.vegan ? <span className='text-emerald-600 font-bold'>🌱</span> : ''} </li>                     
                    )
  }  
  const listEntree = listFormule('entrees')
  const listPlat = listFormule('plats')
  const listDessert = listFormule('desserts')
  const listInspiration = inspiration()

    return(
      <div>
        <p className='text-5xl py-6'>Restaurant Végétarien</p>
        <div className="bg-yellow-50 p-4 mx-4 md:mx-0 rounded mb-4 ">
          <div className="flex justify-center">
            <div className="flex-shrink-0">
            <ExclamationIcon className="h-6 w-6 text-yellow-400" aria-hidden="true" />
            </div>
            <div className="ml-3">
              <p className="font-medium text-yellow-800">
                {props.annonce}
              </p>
            </div>
          </div>
        </div>
        <p className='text-4xl py-4'>Formules Midi</p>
        {listPrix}
        <div className='flex flex-col md:flex-row gap-4 md:items-stretch mt-6'>
          <div className="md:basis-1/3 mx-4 md:mx-0">          
            <div className="flex flex-col rounded-lg shadow-lg overflow-hidden">              
              <div className="flex-1 bg-white/[0.4] flex flex-col justify-between">
                <div className="flex-1">                
                    <p className="text-xl font-semibold text-gray-900 underline">Entrées</p>
                    <ul className='divide-y divide-gray-200'>
                      {listEntree}
                    </ul>                               
                </div>
              </div>
            </div>
          </div>
          <div className="md:basis-1/3 mx-4 md:mx-0">          
            <div className="flex flex-col rounded-lg shadow-lg overflow-hidden">              
              <div className="flex-1 bg-white/[0.4]  flex flex-col justify-between">
                <div className="flex-1">                
                    <p className="text-xl font-semibold text-gray-900 underline">Plats</p>
                    <ul className='divide-y divide-gray-200'>
                      {listPlat}
                    </ul>         
                </div>
              </div>
            </div>
          </div>
          <div className="md:basis-1/3 mx-4 md:mx-0 md:self-auto">          
            <div className="flex flex-col rounded-lg shadow-lg overflow-hidden">              
              <div className="flex-1 bg-white/[0.4]  flex flex-col justify-between">
                <div className="flex-1">                
                    <p className="text-xl font-semibold text-gray-900 underline">Desserts</p>
                    <ul className='divide-y divide-gray-200'>
                      {listDessert}                 
                    </ul>         
                </div>
              </div>
            </div>
          </div>          
        </div>
        <p className='mt-2'><span className='text-emerald-500 font-bold'>🌱</span>: Vegan</p>
        <p className='text-4xl mt-4'>{menuSoir.nom}  {menuSoir.prix}€</p>
        <p className='mb-4'>{menuSoir.information}</p>
        {listInspiration}
        
      </div>        
    )
 }

export default Menu