import React from 'react'

const Footer = (props) => {    
    const restaurant = props.data 
    return(
        <div className='w-80'>
            <div className='w-full min-h-full rounded overflow-hidden footer-card-bg bg-white bg-no-repeat bg-top bg-contain'>
                <div className='h-80'></div>
                <div className="px-4 pt-10 pb-4 text-center ">
                    <p className="text-lg leading-6 font-bold text-gray-900">{restaurant.nom}</p>
                    <p>{restaurant.website}</p>
                    <p>{restaurant.email}</p>
                    <p>{restaurant.adresse}</p>
                    <p>{restaurant.telephone}</p>
                    <p className='py-2 underline'>Horaire d'ouverture</p>
                    <pre className='font-sans text-md'>{restaurant.horaire}</pre>
                </div>
            </div>
        </div>
    )
 }

 export default Footer