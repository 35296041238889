import React, { useEffect, useState} from 'react'
import axios from 'axios'

import Booking from '../components/booking'
import Menu from '../components/menu'
import Header from '../components/header'
import Footer from '../components/footer'
import Loading from '../components/loading'

import { InformationCircleIcon } from '@heroicons/react/solid'

import './index.styles.scss'

const IndexPage = () => {  
  const [restaurant, setRestaurant] = useState([]) 
  const [formule, setFormule] = useState([]) 
  const [calendrier, setCalendrier] = useState([]) 
  const [prix, setPrix] = useState([]) 
  const [menuSoir, setMenuSoir] = useState([]) 
  const [loading, setLoading] = useState(true);

  useEffect(() => {
      const token = 'API-3666adf1e887df2eafc2b4b3f959041b65a31f3b'
      const endpoint= `https://admin.mmao.dev/api/content/`
      const requestRestaurant = axios.get(`${endpoint}item/restaurant?token=${token}`)
      const requestCalendrier = axios.get(`${endpoint}item/calendrier?token=${token}`)
      const requestFormule = axios.get(`${endpoint}items/formule?token=${token}`)
      const requestPrix = axios.get(`${endpoint}items/prix?token=${token}`)
      const requestMenuSoir = axios.get(`${endpoint}item/menuSoir?token=${token}`)
      const getInfo = () => {
          axios.all([requestRestaurant, requestCalendrier, requestFormule, requestPrix, requestMenuSoir])
              .then( axios.spread((...res) => {                  
                  setRestaurant(res[0].data)   
                  setCalendrier(res[1].data)                                 
                  setFormule(res[2].data)                                 
                  setPrix(res[3].data)                                 
                  setMenuSoir(res[4].data)                                 
              }))
              .catch( err => console.error(`Error: ${err}`))
              .finally(() => setLoading(false))
          }
      getInfo()
  }, [])
  
  if (loading) {
    return(
      <div>
        <Loading />
      </div>
    )
  }
  return (  
    <div> 
      <Header />
      <div className='menu-bg bg-cover bg-center py-2'>
      <div className='container w-full md:w-5/6 mx-auto my-2 '>
        <div className='flex md:flex-row flex-col gap-4'>
          <div className='basis md:basis-4/5'>
            <div className='grid justify-items-center text-center papyrus-style'>
              <Menu formule={formule} prix={prix} menuSoir={menuSoir} annonce={restaurant.annonce}/>
            </div>
          </div>          
          <div className='basis md:basis-1/5 w-full bg-white rounded p-2'>
            <div className='grid justify-items-center'>
              <p className='papyrus-style text-5xl py-6'>Réservation</p>
              <div className="bg-blue-50 p-4 mx-4 md:mx-0 rounded mb-4 ">
                <div className="flex">
                  <div className="flex-shrink-0">
                  <InformationCircleIcon className="h-6 w-6 text-blue-200" aria-hidden="true" />
                  </div>
                  <div className="ml-3">
                    <p className="text-sm text-blue-800">
                      Merci de nous signaler toute allergie ou intolérance afin que le Chef adapte le menu le cas échéant.
                    </p>
                  </div>
                </div>
              </div>
              <div className='w-full'>
               <Booking calendrier={calendrier}/>  
              </div>
              
            </div>          
          </div>
        </div>
      </div>         
      </div>
      <div className='h-160 w-full footer-bg p-4 flex justify-center'>        
        <Footer data={restaurant}/>
      </div>
     
    </div>   
  )
}

export default IndexPage