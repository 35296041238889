import React, { useState } from 'react'
import { navigate } from 'gatsby'
import DatePicker, {registerLocale} from 'react-datepicker'
import { subDays, getDay, format, isSameDay} from 'date-fns'
import fr from 'date-fns/locale/fr'
import axios from 'axios'

import 'react-datepicker/dist/react-datepicker.css'

const Booking = (props) => {
  registerLocale('fr', fr)
  const [startDate, setStartDate] = useState(new Date())
  const [isClosedMidi, setIsClosedMidi] = useState(false)
  const [isClosedSoir, setIsClosedSoir] = useState(false)
  // for brocoli sparkform
  const submitUrl = 'https://submit-form.com/4tG1i8G1' 
  // for dev sparkform
  // const submitUrl  ='https://submit-form.com/XjoCDBmU'  

  const vacEnd = new Date(props.calendrier.vacanceEnd)
  const vacDeb = new Date(props.calendrier.vacanceDebut)
  const jourFermeture = props.calendrier.jourFemeture  

  const dayClosed = jourFermeture
           .filter ( day => (day.periode === null || day.periode === '') )
           .map( day => new Date(day.dateFermeture))
  const dayClosedMidi = jourFermeture
           .filter( day => day.periode === 'Midi')
           .map( day => new Date(day.dateFermeture))
  const dayClosedSoir = jourFermeture
           .filter( day => day.periode === 'Soir')
           .map( day => new Date(day.dateFermeture))

  const isClosedDate = (date) => {     
    const day = getDay(date)
    return day !== 0 && day !== 1 
  }

  const isPeriodClosed = (date) => {
    setIsClosedMidi(false)
    setIsClosedSoir(false)
    dayClosedMidi.forEach( jour => isSameDay(jour, date) && setIsClosedMidi(true))
    dayClosedSoir.forEach( jour => isSameDay(jour, date) && setIsClosedSoir(true))
  }
  
  const handleOnChange = (date) => {
    isPeriodClosed(date)
    setStartDate(date)    
  }
  
  const handleSubmit = (e) => {
    e.preventDefault()
    const formData = {
      nom: e.target.nom.value,
      email: e.target.email.value,
      telephone: e.target.telephone.value,
      couverts: e.target.couverts.value,
      date: format(startDate, 'dd/MM/yyyy'),
      midi: e.target.midi.value,
      soir: e.target.soir.value,
      message: e.target.message.value
    }
    axios.post(submitUrl, formData)   
    .then(() => {   
      navigate('/resa')  
    }) 
    .catch(err => {
      console.error(`erreur: ${err}`)
    })
  }

  return (
    <form className='space-y-6 w-full' onSubmit={handleSubmit}>
        <div className='mt-4 relative border border-gray-300 rounded-md px-3 py-2 focus:shadow focus-within:ring-1 focus-within:ring-indigo-600 focus-within:border-indigo-600 mx-4 md:mx-0'>
            <label className='absolute -top-3 left-2 -mt-px inline-block px-1 bg-white text font-medium text-gray-900' htmlFor='nom'>Nom</label>
            
            <input name='nom' className='block w-full border-0 p-0 text-gray-900 placeholder-gray-500 focus-within:outline-none sm:text-sm' type='text' placeholder='votre nom' id='nom' title='Votre nom est incorrect.' required></input>
            
        </div>

        <div className='mt-4 relative border border-gray-300 rounded-md px-3 py-2 focus:shadow focus-within:ring-1 focus-within:ring-indigo-600 focus-within:border-indigo-600 mx-4 md:mx-0'>
            <label className='absolute -top-3 left-2 -mt-px inline-block px-1 bg-white text font-medium text-gray-900' htmlFor='email'>E-mail</label>
           
            <input name='email' className='block w-full border-0 p-0 text-gray-900 placeholder-gray-500 focus-within:outline-none sm:text-sm' type='email' placeholder='votre email' id='email' title='Votre e-mail est incorrect' required></input>
           
        </div>

        <div className='mt-4 relative border border-gray-300 rounded-md px-3 py-2 focus:shadow focus-within:ring-1 focus-within:ring-indigo-600 focus-within:border-indigo-600 mx-4 md:mx-0'>
            <label className='absolute -top-3 left-2 -mt-px inline-block px-1 bg-white text font-medium text-gray-900' htmlFor='telephone'>Telephone</label>
            
            <input name='telephone' className='block w-full border-0 p-0 text-gray-900 placeholder-gray-500 focus-within:outline-none sm:text-sm' type='text' placeholder='+33' id='telephone' required pattern='^([+]?([0-9]|\s)*)' title='Numero au format internationial, ex: +33'></input>
            
        </div>

        <div className='mt-4 relative border border-gray-300 rounded-md px-3 py-2 focus:shadow focus-within:ring-1 focus-within:ring-indigo-600 focus-within:border-indigo-600 mx-4 md:mx-0'>
          <label className='absolute -top-3 left-2 -mt-px inline-block px-1 bg-white text font-medium text-gray-900' htmlFor='couvert'>Couverts</label>
                        
          <select name='couverts' className='block w-full text-base bg-white focus:outline-none sm:text-sm rounded'>
              <option value='1'>1</option>
              <option value='2'>2</option>
              <option value='3'>3</option>
              <option value='4'>4</option>
              <option value='5'>5</option>
              <option value='6'>6</option>
              <option value='7'>7</option>
              <option value='8'>8</option>
              <option value='9'>9</option>
              <option value='10'>10</option>
          </select>                           
        </div>
       

        <div className='mt-4 relative border border-gray-300 rounded-md px-3 py-2 focus:shadow focus-within:ring-1 focus-within:ring-indigo-600 focus-within:border-indigo-600 mx-4 md:mx-0'>
          <label className='absolute -top-3 left-2 -mt-px inline-block px-1 bg-white text font-medium text-gray-900' htmlFor='dateResa'>Date</label>
        
          <DatePicker 
            name="Date Reservation"
            selected={startDate} 
            onChange={handleOnChange} 
            className='block w-full border-0 p-0 text-gray-900 placeholder-gray-500 focus-within:outline-none sm:text-sm'
            locale="fr"
            placeholderText='dd/mm/yyyy'
            minDate={subDays(new Date(), 0)}
            dateFormat='dd/MM/yyyy'
            filterDate={isClosedDate}
            excludeDateIntervals={[{start: subDays(vacDeb, 1), end: vacEnd}]}
            excludeDates={dayClosed}
          />        
        </div>

        <div className='mt-4 relative border border-gray-300 rounded-md px-3 py-2 focus:shadow focus-within:ring-1 focus-within:ring-indigo-600 focus-within:border-indigo-600 mx-4 md:mx-0'>
        <label className='absolute -top-3 left-2 -mt-px inline-block px-1 bg-white text font-medium text-gray-900' htmlFor='heuremidi'>Heure</label>
          <div className='flex flex-row'>
            <div className='basis-1/2 mr-4 md:mr-2'>
                <select name='midi' className={`block w-full text-base focus:outline-none sm:text-sm rounded ${isClosedMidi? 'opacity-25 cursor-not-allowed bg-gray-700': 'bg-white'}`} disabled={isClosedMidi} >
                <option value=''>Midi</option>
                <option value='12h00'>12h00</option>
                <option value='12h15'>12h15</option>
                <option value='12h30'>12h30</option>
                <option value='12h45'>12h45</option>
                <option value='13h00'>13h00</option>
                <option value='13h15'>13h15</option>
                <option value='13h30'>13h30</option>
                <option value='13h45'>13h45</option>                      
                </select>
            </div>    
            <div className='basis-1/2 pl-2  border-gray-300 border-l'>
                <select name='soir' className={`block w-full text-base focus:outline-none sm:text-sm rounded ${isClosedSoir? 'opacity-25 cursor-not-allowed bg-gray-700': 'bg-white'}`} disabled={isClosedSoir}>
                <option value=''>Soir</option>
                <option value='19h00'>19h00</option>
                <option value='19h15'>19h15</option>
                <option value='19h30'>19h30</option>
                <option value='19h45'>19h45</option>
                <option value='20h00'>20h00</option>
                <option value='20h15'>20h15</option>
                <option value='20h30'>20h30</option>
                <option value='20h45'>20h45</option>                   
                </select>
            </div>              
          </div>  
        </div>
        

        <div className='mt-4 relative border border-gray-300 rounded-md px-3 py-2 focus:shadow focus-within:ring-1 focus-within:ring-indigo-600 focus-within:border-indigo-600 mx-4 md:mx-0'>
          <label className='absolute -top-3 left-2 -mt-px inline-block px-1 bg-white text font-medium text-gray-900' htmlFor='message'>Message</label>
        
          <textarea className='block w-full border-0 p-0 text-gray-900 placeholder-gray-500 focus-within:outline-none sm:text-sm' name='message' placeholder='Votre message' id='message'></textarea>
        
        </div> 

        <div className="block w-90 mx-4 md:mx-0 mt-4">        
          <button type='submit' className="w-full flex justify-center border py-2 border-transparent rounded-md shadow-sm text-sm font-medium text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500">Réserver</button>        
        </div>            
    </form>
  )
}

export default Booking